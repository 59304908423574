const trixCountNumberCaractere = () => {
  const alertDiv = document.getElementById('alert-count');
  if (alertDiv){
    var textArea = document.getElementById("article_body");

    // Choper le HTML et le convertir en text
    var tempTextArea = document.getElementById("article_body");

    var htmlToText = tempTextArea.textContent

    // Calculer le nombre de caractère
    textArea.addEventListener("input", function(){
      var htmlToText = this.textContent
      var currentLength = htmlToText.length;
    //Afficher la div si y'a des caractères ou non
      if (currentLength > 0) {
        alertDiv.style.display = "block";
        alertDiv.innerHTML = "";
        alertDiv.insertAdjacentHTML('afterbegin', `${currentLength} caractères sur 3500 demandés`)
      }

    })

    // Afficher le nombre de caractère

  }
}

export { trixCountNumberCaractere };
  //alertDiv.style.display = 'none'
  // id = article_body
