window.addEventListener("trix-file-accept", function(event) {
  const acceptedTypes = ['image/jpg','image/jpeg', 'image/png']
  if (!acceptedTypes.includes(event.file.type)) {
    event.preventDefault()
    alert("Uniquement des fichiers jpg, jpeg ou png")
  };

  // const maxFileSize = 1600 * 1600 // 1MB
  const maxFileSize = 10240000 // 1MB
  if (event.file.size > maxFileSize) {
    event.preventDefault()
    alert("L'image fait plus de 10 Mo. Merci de réduire le poids en allant dans l'onglet Aide > Réduire le poids des images.")
  }

})
